import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';

function SSO() {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;
    async function checkUser() {
      // router.query is being very squirrely here, so we're using the router.asPath and parsing it ourselves
      const callbackUrl = router.asPath.split('callbackUrl=')[1].split('#')[0];
      try {
        await Auth.currentAuthenticatedUser();
        router.push(callbackUrl || '/');
      } catch (error) {
        router.push(`/login?callbackUrl=${router.query.callbackUrl}`);
      }
    }
    checkUser();
  }, [router]);
  return <></>;
}

export default SSO;
